<template>
    <div class="page-container">
    <Header/>
        <div class="banner-mod">
            <img src="/static/images/auth-banner.png" />
        </div>
      <router-view></router-view>
    <Footer :auth="isAuth"/>
  </div>
  </template>
  
  <script>
  //引入组件
  import Header from "@/layout/components/header";
  import Footer from "@/layout/components/footer";
  
  export default {
    name: 'auth',
    components: {Header, Footer},
    data() {
      return {
        isAuth:true  //是否认证页（认证页面的footer背景色不同） 传递给子组件footer 
      }
    },
    created() {
    },
    mounted() {
      console.log("front布局-mounted2");
      //window.scrollTo(0, 0);
    },
    methods: {}
  }
  </script>
  <style lang="scss" scoped>
  .page-container{padding-top:100px;}
  .banner-mod{position:relative;width:100%;height:300px;
    img{display: block;width:1920px;position: absolute;top:0;left:50%;margin-left:-960px;}
}
  </style>
  